import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import portfolioPieceStyles from "./portfolio-piece.module.scss"
import BackButton from "../components/backbutton"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title || "Phil Cohn"}
        description={'Portfolio of custom AR and 3D effects.'}
        image={'/static/favicon.ico'}
        article
      />
      <div className={portfolioPieceStyles.portfolioContainer}>
        <div className={portfolioPieceStyles.backButton}>
          <BackButton destination={`/work`}/>
        </div>

        <h1>{post.frontmatter.title}</h1>
        <div className={portfolioPieceStyles.portfolioContent} dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        category
      }
    }
  }
`